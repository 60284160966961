
import { inject } from "vue";
import { Database, Auth } from "vuebase";
import { Product } from "../main";

export default {
    name: "Home",
    setup() {
        const database = inject(Database.InjectionKey);
        const products = database?.collection<Product>("products").documents();
        const auth = Auth.auth();

        return {
            auth,
            products,
        };
    },
};

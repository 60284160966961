<template>
    <c-panel v-if="!auth.user">
        <c-button-group class="mt-5 pt-5 flex f-justify-center">
            <c-button class="large" @click="auth.signIn()">
                <template #icon>
                    <i class="fas fa-user"></i>
                </template>
                Sign In
            </c-button>
        </c-button-group>
    </c-panel>
    <c-panel v-else>
        <h1 class="mb-3">Admin Control Panel</h1>
        <c-grid class="spacing-sm-all widths-100-all">
            <c-grid-item>
                <c-button class="large" @click="$router.push('/admin/orders')">
                    <template #icon>
                        <i class="fas fa-shopping-cart"></i>
                    </template>
                    Orders
                </c-button>
            </c-grid-item>
            <c-grid-item>
                <c-button class="large" @click="$router.push('/admin/requests')">
                    <template #icon>
                        <i class="fas fa-question"></i>
                    </template>
                    Requests
                </c-button>
            </c-grid-item>
            <c-grid-item>
                <c-button class="large" @click="$router.push('/admin/products')">
                    <template #icon>
                        <i class="fas fa-money-check-alt"></i>
                    </template>
                    Products
                </c-button>
            </c-grid-item>
            <c-grid-item>
                <c-button class="large" @click="$router.push('/admin/artists')">
                    <template #icon>
                        <i class="fas fa-users"></i>
                    </template>
                    Artists
                </c-button>
            </c-grid-item>
            <c-grid-item>
                <c-button class="large" @click="$router.push('/admin/feed')">
                    <template #icon>
                        <i class="fas fa-images"></i>
                    </template>
                    Feed
                </c-button>
            </c-grid-item>
            <c-grid-item>
                <c-button class="large" @click="$router.push('/admin/uploads')">
                    <template #icon>
                        <i class="fas fa-upload"></i>
                    </template>
                    Uploads
                </c-button>
            </c-grid-item>
            <c-grid-item>
                <c-button class="large red" @click="auth.signOut()">
                    <template #icon>
                        <i class="fas fa-user"></i>
                    </template>
                    Sign Out
                </c-button>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { inject } from "vue";
import { Database, Auth } from "vuebase";
import { Product } from "../main";

export default {
    name: "Home",
    setup() {
        const database = inject(Database.InjectionKey);
        const products = database?.collection<Product>("products").documents();
        const auth = Auth.auth();

        return {
            auth,
            products,
        };
    },
};
</script>
